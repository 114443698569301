<template>
  <div class="column is-3">
    <div class="card">
      <div class="card-content">
        <div class="content">
          <h1 class="title">{{ title }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderItem',
  props: ['title'],
};
</script>

<style lang="scss" scoped>
.card {
  background-color: $white;
  padding: 1rem;
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: 769px) {
  .column {
    padding: 0;

    &:nth-child(1n) {
      padding-left: 0.75rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .column {
    padding: 0 1rem;
  }
}
</style>
